

import React from 'react';
import Rental from 'views/RaisingStart2022';

const RentalPage = () => {
  return <Rental />;
};

export default RentalPage;
