import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import useMediaQuery from '@mui/material/useMediaQuery';

import Main from 'layouts/Main';
import Container from 'components/Container';
import { Form } from './components';

import Analytics from 'analytics'
import googleAnalytics from '@analytics/google-analytics'
import Typography from '@mui/material/Typography';
import { Divider } from '@mui/material';
import Assaa from './Assaa';
import Last from './Last';


const analytics = Analytics({
  app: 'awesome-app',
  plugins: [
    googleAnalytics({
      measurementIds: ['G-X34JYK01Q0']
    })
  ]
})

analytics.page()

const Kindergarten= () => {
  const theme = useTheme();
  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  return (
    <Main>
        <Container  maxWidth={0.78}  paddingTop={'0 !important'}>
          <Grid>
          <Typography variant={'h4'} sx={{ fontWeight: 700 }}
              align={'center'}>
               <br/>RISING STAR
              </Typography>
              <Typography component='p' align={'justify'} color="text.secondary">
              <br/>Master Vedansh Rao of LKG represented NPS Yeshwanthpur school in Interschool Speed Skating Meet held on 28th February 2022 at Soundarya School, Bangalore and secured 2nd place in Tenacity category.
              <br/>
              <Container maxWidth={0.88}  paddingTop={'0 !important'}>
            <Form />
              </Container>
              <Divider>

              </Divider>
              <Typography component='p' align={'justify'} color="text.secondary"><br/><br/>
              TATTVAM JUNIOR CIRCUIT 2021- AITA (All India Tennis Association) TALENT SERIES 14 was conducted at Karnataka States Lawn Tennis Association (KSLTA) stadium from 23 October to 29 October, in which YASH took part from qualifying rounds. Yash qualified to mains by winning all 3 qualified matches, to get selected for the main tournament. Tournament date – 23 October to 29 October. Yash have bagged runner –up prize in KSLTA tournament Finals.
              </Typography>
              <Typography>
              KSLTA tournament Finals<br/>
              Classes: 9-12<br/>
              Date: 23 October to 29 October<br/>
              <br/>Yash had a win streak of 7 matches in this tournament, which is inclusive of 3 matches in qualifier and 4 matches in mains to reach Finals, however stood runner-up.
              </Typography>
              </Typography>
              <Container maxWidth={0.88}  paddingTop={'0 !important'}>
            <Assaa />
              </Container>
              <Divider>
                
              </Divider>
              <br/><br/>
              <Typography component='p' align={'justify'} color="text.secondary"><br/><br/>
              Tisya Sonar of Class 6 "B" has represented Karnataka at National level Swimming Championships under Group III Girls category and got the 1st place in 100M Breaststroke.
              </Typography>
              <Container maxWidth={0.88}  paddingTop={'0 !important'}>
            <Last />
              </Container>
          </Grid>

        </Container>
        
    </Main>
  );
};

export default Kindergarten;
